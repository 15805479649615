import { render, staticRenderFns } from "./TopAction.vue?vue&type=template&id=0c19c68b&scoped=true&"
import script from "./TopAction.vue?vue&type=script&lang=js&"
export * from "./TopAction.vue?vue&type=script&lang=js&"
import style0 from "./TopAction.vue?vue&type=style&index=0&id=0c19c68b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c19c68b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VBtn,VIcon,VPagination})
