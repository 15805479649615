<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Danh sách folder</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <div class="">
        <div class="font-weight-bold mb-2">
          Hiển thị trên website
        </div>
        <v-radio-group v-model="selectedSite" class="mt-0" dense hide-details>
          <v-radio label="Tất cả" value="ALL"></v-radio>
          <v-radio
            v-for="(site, index) in ARRAY_LIST_MODEL_SITES"
            :key="index"
            :label="site.text"
            :value="site.id"
          ></v-radio>
          <v-radio label="Bị ẩn" value="NONE"></v-radio>
        </v-radio-group>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ARRAY_LIST_MODEL_SITES, LIST_MODEL_SITES } from "@/core/constant";

export default {
  props: {
    siteFilter: {
      type: String
    }
  },

  data() {
    return { ARRAY_LIST_MODEL_SITES, LIST_MODEL_SITES };
  },

  computed: {
    selectedSite: {
      get() {
        return this.siteFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "siteFilter",
          value: val
        });
      }
    }
  },

  created() {
    this.$store.dispatch("PRODUCT_TYPE/getAllProductTypes");
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
  .tp-filter-scroll {
    overflow-y: auto;
  }
}
</style>
