<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :site-filter="siteFilter"
        @updateFilterProperty="updateFilterProperty($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";
import { updateFilterProperty } from "@/core/composables";

export default {
  components: {
    DataTableItems,
    FilterArea,
    TopAction
  },
  data() {
    return {
      currentPage: 1,
      itemPerPage: 50,
      searchKey: null,
      siteFilter: "ALL"
    };
  },

  watch: {
    "$route.query"(val) {
      this.getCustomizeFolders(val);
    }
  },

  async created() {
    const route = this.$route;

    await this.getCustomizeFolders(route.query);
  },

  methods: {
    async pushRouter() {
      await this.$router.push({
        name: "website_customize-folder",
        query: {
          limit: this.itemPerPage,
          page: this.currentPage,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          site: this.siteFilter
        }
      });
    },

    async getCustomizeFolders(query) {
      if (query.page) {
        // Set current page
        this.currentPage = parseInt(query.page);

        // Set item per page
        this.itemPerPage = parseInt(query.limit);

        // Set search key
        this.searchKey = query.search || null;

        // Set filtered site

        this.siteFilter = query.site || "ALL";

        // Get guarantee vouchers
        await this.$store.dispatch("CUSTOMIZE_FOLDER/getCustomizeFolders", {
          filter: {
            site: this.siteFilter
          },
          cur_page: this.currentPage,
          per_page: this.itemPerPage,
          search: this.searchKey
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateFilterProperty(data) {
      updateFilterProperty(this, data.filterProperty, data.value);
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
