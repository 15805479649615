<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="
      customizeFolderStatusRequest.value === 'loading-getCustomizeFolders'
    "
    loading-text="Đang tải dữ liệu"
    :items="customizeFolders"
    item-key="id"
    @click:row="goToDetailPage($event)"
  >
    <template v-slot:[`item.last_updated_by_user`]="{ item }">
      {{ item.last_updated_by_user ? item.last_updated_by_user.name : "N/A" }}
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <button-view-sku-in-website
        v-if="item.urls"
        :urls="item.urls"
      ></button-view-sku-in-website>
    </template>
  </v-data-table>
</template>

<script>
import { HEADERS_CUSTOMIZE_FOLDER_TABLE } from "@/modules/Website/constants";
import ButtonViewSkuInWebsite from "./ButtonViewSkuInWebsite.vue";

export default {
  components: {
    ButtonViewSkuInWebsite
  },

  data() {
    return {
      headers: HEADERS_CUSTOMIZE_FOLDER_TABLE,
      thinkproFrontStoreUrl: process.env.VUE_APP_THINKPRO_FRONT_STORE
    };
  },

  computed: {
    customizeFolders() {
      return this.$store.getters["CUSTOMIZE_FOLDER/customizeFolders"];
    },
    customizeFolderStatusRequest() {
      return this.$store.getters["CUSTOMIZE_FOLDER/statusRequest"];
    }
  },

  methods: {
    async goToDetailPage(item) {
      await this.$store.dispatch("CUSTOMIZE_FOLDER/resetCustomizeFolder");
      await this.$store.dispatch(
        "CUSTOMIZE_FOLDER/resetCustomizeFolderCategory"
      );
      await this.$store.dispatch(
        "CUSTOMIZE_FOLDER/resetCustomizeFolderFilters"
      );

      await this.$router.push({
        name: "website_customize-folder-detail",
        params: {
          customizeFolderId: item.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
